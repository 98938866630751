import {createAction} from 'redux-actions';
import {apiPost} from './api';

const actions = {
  forward_granite: createAction('ADMIN_FORWARD_GRANITE'),
  forward_granite_failed: createAction('ADMIN_FORWARD_GRANITE_FAILED'),
  forward_granite_succeeded: createAction('ADMIN_FORWARD_GRANITE_SUCCEEDED'),
  clear_state: createAction('ADMIN_FORWARD_GRANITE_CLEAR_STATE'),
};
export default actions;

export const forwardGranite = (data) => apiPost('admin/forward/pdf', data, actions.forward_granite, actions.forward_granite_succeeded, actions.forward_granite_failed, {'Content-Type': 'multipart/form-data'});

export const forwardSendQuote = data =>apiPost('admin/forward/quote', data, actions.forward_granite, actions.forward_granite_succeeded, actions.forward_granite_failed);
export const onClearState = () => ({type:actions.clear_state.toString()});