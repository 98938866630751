import _ from 'lodash';

export const updateProps = (state, prop, values) => ({...state, [prop]: {...state[prop], ...values}});

export const extractErrors = (response, _default = {}) => {
  for (const path of ['errors', 'data.errors', 'data.data.errors']) {
    if (!_.isEmpty(_.get(response, path))) return _.get(response, path);
  }

  for (const path of ['message', 'data.message', 'data.data.message']) {
    if (!_.isEmpty(_.get(response, path))) return {error: _.get(response, path)};
  }

  return !_.isEmpty(_default) ? _default: 'Network error, please try later...';
};
