import {handleActions} from 'redux-actions';
import actions from '../actions/users';
import {API_END, API_START} from '../constants/api.constants';
import _ from 'lodash';

export default handleActions({
    [actions.send_request]: (state, action) => {
        switch (action.payload) {
            case API_START:
                return {
                    ...state, errors: {},
                    isLoading: true,
                    success: false,
                    user_created: false,
                    rol_added: false,
                    rol_deleted: false,
                    rol_updated: false,
                    user_deleted: false,
                    get_users: false
                };
            case API_END:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },

    [actions.send_request_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');
        return {...state, errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message'), user_created: false};
    },
    [actions.create_succeeded]: (state, action) => {
        const user = _.pick(action.payload, ['email']);
        const new_state = {...state, new_user: user, user_created: true};

        return updateUsers(new_state, user);
    },

    [actions.force_delete_user_succeeded]: (state, action) => {
        const user = _.pick(action.payload, ['email']);
        const new_state = {...state, user_deleted: true};
        return updateUsers(new_state, user, true);
    },

    [actions.add_role_succeeded]: (state, action) => {
        const user = _.pick(action.payload, ['email', 'sections']);
        const new_state = {...state, rol_added: true};
        return updateUsers(new_state, user);
    },

    [actions.delete_role_succeeded]: (state, action) => {
        const user = _.pick(action.payload, ['email', 'sections']);
        const new_state = {...state, rol_deleted: true};
        return updateUsers(new_state, user);
    },

    [actions.update_roles_succeeded]: (state, action) => {
        const user = _.pick(action.payload, ['email', 'sections']);
        const new_state = {...state, rol_updated: true};
        return updateUsers(new_state, user);
    },

    [actions.get_users_succeeded]: (state, action) => {
        return {...state, users: action.payload, get_users: true};
    },


}, getDefaults());

const updateUsers = (state, user, remove_user = false) => {
    if (remove_user) {
        state.users = state.users.filter(u => u.email !== user.email);
        return state;
    }
    if (state.users.findIndex(u => u.email === user.email) === -1) {
        state.users.push(user);
        return state;
    }

    state.users = state.users.map(u => {
        if (u.email === user.email) return user;
        return u;
    });
    return state;
};

export function getDefaults() {
    return {
        users: [],
        isLoading: false,
        errors: {},
        new_user: {},
        get_users: false,
        user_created: false,
        rol_added: false,
        rol_deleted: false,
        rol_updated: false,
        user_deleted: false,
    };
}