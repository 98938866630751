import React from 'react';
import {Container, Row} from 'reactstrap';
import img_ssp_logo from '../images/logo-ssp.jpg';

export default class Header extends React.Component {

    render() {
        return (
            <header>
                <Container>
                    <Row className="d-flex justify-content-between align-items-sm-center align-items-lg-start">
                        <div className="col-sm-12">
                            <img className="img-fluid bigger-xxs" src={img_ssp_logo} alt="ssp insurance"/>
                        </div>
                    </Row>
                </Container>
            </header>
        );
    }
}
