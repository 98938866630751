import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import config from "./config";
import {store} from "./redux/configure.store";

/**
 * Master Error Handler for the Whole App
 * This should be used only as a wrapper for the App component, other errors should be implemented for sub-components
 */
export default class AppError extends Component {
    static propTypes = {
        reset: PropTypes.func.isRequired,
    };
    state = {
        hasError: false,
        details: {
            error: null,
            info: null,
        },
    };

    async componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({hasError: true, details: {error, info}});
        try {
            const redux_state = store.getState();
            await this.sendErrorToBackend(error, info, JSON.stringify(redux_state));
        } catch (e) {
            console.error(e)
        }
    }

    sendErrorToBackend = async (error, info, redux_state) => {
        return await axios.post(`${config.backend_url}/api/errors`, {error: error.toString(), info: info.componentStack, redux_state})
    };

    handleReset() {
        this.props.reset();
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="container text-center jumbotron">
                    <h1>Something went wrong.</h1>
                    <p>

                    </p>
                    <div className="btn btn-danger" onClick={this.handleReset.bind(this)}>
                        reset
                    </div>
                </div>
            );
        }
        return this.props.children;
  }
}
