import React, {Suspense, lazy} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import './app.scss';

import Header from './layout/header';

const Admin = lazy(() => import('./containers/admin'));
const AdminPassword = lazy(() => import('./containers/admin.password'));
const AdminForwardGranite = lazy(() => import( './containers/admin.forward.granite'));
const Users = lazy(() => import( './containers/users'));
const Default = lazy(() => import( './components/default'));

class App extends React.Component {
  render() {
    return (
        <div className="app">
          <Header/>
          <Suspense fallback={<div className="main-content"><p className='text-center m-5'>Please Wait...</p></div>}>
            <div className="main-content">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/admin"/>
                </Route>
                {/* Admin Pages */}
                <Route exact path='/admin' component={Admin}/>
                <Route path='/admin/reset-password' component={AdminPassword}/>
                <Route path='/admin/forward/granite' component={AdminForwardGranite}/>
                <Route path='/admin/users' component={Users}/>
                <Route component={Default}/>
              </Switch>
            </div>
          </Suspense>
        </div>
    );
  }
}

export default App;
