
const STATE_QUOTED = 'quoted';
const STATE_BUY_PAGE_SUBMITTED = 'buy_page_submitted';
const STATE_PAID = 'paid';
const STATE_OFFLINE_ONLY = 'offline_only';
const STATE_LICENCE_UNDER_12 = 'lead_licence_under_12';
const STATE_DRIVER_UNDER_21 = 'lead_driver_under_21';

const states = {
  STATE_QUOTED,
  STATE_BUY_PAGE_SUBMITTED,
  STATE_PAID,
  STATE_OFFLINE_ONLY,
  STATE_LICENCE_UNDER_12,
  STATE_DRIVER_UNDER_21
};

const timeTable = {
  week: {
    start: '9:00am',
    end: '7:00pm',
    text: 'Mon-Fri 9am-7pm',
  },
  saturday: {
    start: '10:00am',
    end: '2:00pm',
    text: 'Sat 10am-2pm',
  },
};

const phoneNumbers = {
  undefined: '0330 808 1500',
  '': '0330 808 1500',
  fastfood: '0330 808 9056',
};

const isProduction = () => process.env.NODE_ENV === 'production';
export {isProduction};

console.log(process.env.NODE_ENV);

const config = {
  backend_url: `${isProduction() ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL}`,
  phoneNumbers,
  timeTable,
  states,
};

export default config;