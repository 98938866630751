import {handleActions} from 'redux-actions';
import actions from '../actions/admin.forward';
import {API_END, API_LOADING, API_READY, API_START} from '../constants/api.constants';
import {extractErrors, updateProps} from './utils';

export default handleActions({
  [actions.forward_granite]: (state, action) => {
    switch (action.payload) {
      case API_START:
        return updateProps(state, 'granite', {errors: getDefaults().errors, result: getDefaults().result, status: API_LOADING});
      case API_END:
        return updateProps(state, 'granite', {status: API_READY});
      default:
        return state;
    }
  },
  [actions.forward_granite_succeeded]: (state, action) => updateProps(state, 'granite', {result: action.payload}),
  [actions.forward_granite_failed]: (state, action) => updateProps(state, 'granite', {errors: extractErrors(action.payload)}),
  [actions.clear_state]: (state, action) => getDefaults(),

}, getDefaults());

export function getDefaults() {
  return {
    granite: {
      result: {},
      status: API_READY,
      errors: {},
    },
  };
}