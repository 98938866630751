import axios from 'axios';
import {API, API_END, API_ERROR, API_START, API_SUCCESS} from '../constants/api.constants';
import {apiError, apiStart, apiEnd, apiSuccess} from '../actions/api';

const apiMiddleware = ({dispatch}) => next => action => {
  if (action.type !== API) return next(action);
  const {
    url,
    method,
    data,
    accessToken,
    onSuccess,
    onFailure,
    headers,
  } = action.payload;
  let {
    label,
  } = action.payload;

  if (label) typeof label === 'function' ? dispatch(label(API_START)) : dispatch(apiStart(label));

  axios
      .request({
        url,
        method,
        headers: {'content-type': 'application/json', ...headers},
        [['GET'].includes(method) ? 'params' : 'data']: data,
      })
      .then(({data}) => {
        if (label) typeof label === 'function' ? dispatch(label(API_SUCCESS)) : dispatch(apiSuccess(label));
        dispatch(onSuccess(data));
      })
      .catch(({response}) => {
        if (label) typeof label === 'function' ? dispatch(label(API_ERROR)) : dispatch(apiError(label));
        dispatch(onFailure(response));
      })
      .finally(() => {
        if (label) typeof label === 'function' ? dispatch(label(API_END)) : dispatch(apiEnd(label));
      });
};

export default apiMiddleware;