import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';

import apiMiddleware from './middleware/api.middleware';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import expireIn from './transform.expire.reducers';

import adminReducer from './reducers/admin';
import adminForwardReducer from './reducers/admin.forward';
import usersReducer from './reducers/users';

const initialState = {};

const middlewares = [thunk, apiMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const rootReducer = combineReducers({
    admin: adminReducer,
    adminForward: adminForwardReducer,
    user: usersReducer
});

const persistedReducers = persistReducer({
        key: 'iros-marketplace',
        storage,
        whitelist: ['admin'],
    },
    rootReducer
);

let store = createStore(
    persistedReducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
);

//clear van from local storage
function clearStorage() {
    const storageKey = 'persist:iros-marketplace';
    let storage = JSON.parse(localStorage.getItem(storageKey));

    localStorage.setItem(storageKey, JSON.stringify(storage));
}

let persistor = persistStore(store);

export {
    store, persistor, clearStorage
};